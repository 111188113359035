

























import { Component } from 'vue-property-decorator';
import { BModal, BvModalEvent } from 'bootstrap-vue';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import UserService from '@/services/UserService';

@Component
export default class ImportUsersModal extends mixins(Toasts) {
  protected title = 'Upload User List';
  protected file!: File;
  public show(): void {
    (this.$refs.modal as BModal).show();
  }

  protected handleClose(e: BvModalEvent): void {
    this.$emit('close', e);
  }

  protected submitFile(): void {
    UserService.importUsersCSV(this.file)
      .then(() => {
        this.showToast('Users imported', 'Users have been imported.', 'success');
        this.$router.go(0);
      })
      .catch((backendError) => {
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to import', 'Could not import users. ' + backendError.response.data.message, 'danger');
        }
      });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  protected handleFileUpload(event): void {
    this.file = event.target.files[0];
  }
}
