import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class UserTagChanges {
  @JsonProperty('tagIds')
  private _tagIds: number[];

  constructor(tagIds: number[]) {
    this._tagIds = tagIds;
  }

  get tagIds(): number[] {
    return this._tagIds;
  }

  set tagIds(value: number[]) {
    this._tagIds = value;
  }
}
