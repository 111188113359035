




































































































































































import { Component, Vue } from 'vue-property-decorator';
import UserService from '@/services/UserService';
import { Action, State } from 'vuex-class';
import UserInfo from '@/models/users/UserInfo';
import Tag from '@/models/users/Tag';
import TagService from '@/services/TagService';
import UserTagged from '@/models/users/UserTagged';
import { hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import Project from '@/models/Project';
import ReviewAssignment from '@/models/reviews/ReviewAssignment';
import UserTagChanges from '@/models/users/UserTagChanges';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import ImportUsersModal from '@/components/modals/UserModals/ImportUsersModal.vue';

@Component({
  components: { ImportUsersModal },
  beforeRouteUpdate(to, from, next) {
    if (!(this as UserAdministration).changedValue) {
      window.removeEventListener('beforeunload', (this as UserAdministration).handleBeforeUnload);
      next();
    } else if ((this as UserAdministration).confirmUnsavedChanges()) {
      next();
    } else {
      next(false);
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!(this as UserAdministration).changedValue) {
      window.removeEventListener('beforeunload', (this as UserAdministration).handleBeforeUnload);
      next();
    } else if ((this as UserAdministration).confirmUnsavedChanges()) {
      window.removeEventListener('beforeunload', (this as UserAdministration).handleBeforeUnload);
      next();
    } else {
      next(false);
    }
  },
})
export default class UserAdministration extends mixins(Toasts) {
  protected readonly PossibleAction = PossibleAction;

  protected shouldBeVisible(action: PossibleAction, project?: Project, assignment?: ReviewAssignment): boolean {
    const permission = hasPermission(action, project, assignment);
    return permission != null && permission;
  }

  protected getState(state: boolean | null, value: string | null, maxLength: number): boolean | null {
    if (value && value.length > 0) {
      const lenCheck = value.length <= maxLength;
      if (!lenCheck) {
        return false;
      }
    }
    return state;
  }

  @Action('setAvailableUsers', { namespace: 'userManagement' })
  private setAvailableUsers!: (value: UserInfo[]) => void;

  @State('availableUsers', { namespace: 'userManagement' })
  protected availableUsers!: UserInfo[];

  @Action('setAvailableTags', { namespace: 'userManagement' })
  protected setAvailableTags!: (value: Tag[]) => void;

  @State('availableTags', { namespace: 'userManagement' })
  protected availableTags!: Tag[];

  protected breadcrumbs: { text: string; to?: string; active?: boolean }[] | null = null;

  protected userFields = [
    {
      key: 'uid',
      label: 'Username',
      sortable: false,
    },
    {
      key: 'fullName',
      label: 'Name',
      sortable: false,
    },
    {
      key: 'tagIds',
      label: 'Tags',
      sortable: false,
    },
    {
      key: 'actions',
      label: 'Actions',
      sortable: false,
    },
  ];

  protected tagFields = [
    {
      key: 'name',
      label: 'Name',
    },
  ];

  protected prevPageNo = 0;
  protected currentPageNo = 1;
  protected readonly pageSize: number = 10;

  protected systemUserWithTags: UserTagged[] = [];

  protected tagCreationEnteredName = '';
  protected tagCreationValidatorState: boolean | null = null;
  protected tagCreationInProgress = false;
  protected tagCreationErrorMessage = '';

  protected selectedTag: Tag | null = null;
  protected changedValue = false;

  protected loadCounter = 0;

  mounted(): void {
    if (hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION)) {
      this.breadcrumbs = [
        { text: 'Home', to: '/' },
        { text: 'Administration', active: true },
      ];
      this.loadUserInfos();
    } else if (hasPermission(PossibleAction.CAN_NAVIGATE_TAGS)) {
      this.breadcrumbs = [
        { text: 'Home', to: '/' },
        { text: 'System Tags', active: true },
      ];
      this.loadUserInfos();
    } else {
      this.$router.replace({ path: '/' });
    }
  }

  protected deleteUser(user: UserTagged): void {
    if (confirm('Do you really want to delete the user ' + user.uid + '?')) {
      this.setLoading(true);
      UserService.deleteUser(user.uid)
        .then(() => {
          this.reloadTable();
          this.setLoading(false);
        })
        .catch((backendError) => {
          this.setLoading(false);
          if (backendError.response.status === 403) {
            this.showToast('Action denied', 'You do not have the required rights.', 'danger');
          } else {
            this.showToast(
              'Failed to delete',
              'Could not delete user. ' + backendError.response.data.message,
              'danger'
            );
          }
        });
    }
  }

  protected editUser(user: UserTagged): void {
    this.navigate('/system/edituser/' + user.uid);
  }

  protected navigate(name: string): void {
    this.$router.push(name);
  }

  protected loadUserInfos(): void {
    if (hasPermission(PossibleAction.CAN_GET_USER_INFO)) {
      this.setLoading(true);
      UserService.getAllUserInfos()
        .then((userInfos) => {
          this.setAvailableUsers(userInfos);
          if (hasPermission(PossibleAction.CAN_GET_TAG)) {
            Vue.nextTick(() => {
              this.loadTags();
            });
          }
        })
        .catch((backendError) => {
          this.setLoading(false);
          if (backendError.response.status === 403) {
            this.showToast('Action denied', 'You do not have the required rights.', 'danger');
          } else {
            this.showToast('Failed to load', 'Could not load users.', 'danger');
          }
        });
      window.addEventListener('beforeunload', this.handleBeforeUnload);
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-module-boundary-types
  protected handleBeforeUnload(event: any): void {
    if (this.changedValue) {
      event.returnValue = this.confirmUnsavedChangesString;
    }
  }

  protected loadTags(): void {
    if (hasPermission(PossibleAction.CAN_GET_TAG)) {
      TagService.getAll()
        .then((tags) => {
          this.setAvailableTags(
            tags.sort((a: Tag, b: Tag) => {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            })
          );
        })
        .catch((backendError) => {
          if (backendError.response.status === 403) {
            this.showToast('Action denied', 'You do not have the required rights.', 'danger');
          } else {
            this.showToast('Failed to load', 'Could not load tags.', 'danger');
          }
        })
        .finally(() => {
          this.setLoading(false);
          if (this.availableUsers.length > 0) {
            if (hasPermission(PossibleAction.CAN_GET_USER_TAGS)) {
              this.loadNextUsers();
            }
          }
        });
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  public reloadTable(): void {
    this.setLoading(true);
    this.systemUserWithTags = [];
    this.prevPageNo = 0;
    this.loadUserInfos();
  }
  protected loadNextUsers(): void {
    if (this.prevPageNo < this.currentPageNo) {
      this.loadCounter = 0;
      this.setLoading(true);

      let startIndex = this.pageSize * this.prevPageNo;
      if (startIndex + this.pageSize - 1 < this.availableUsers.length) {
        this.loadCounter = this.pageSize;
      } else {
        this.loadCounter = this.availableUsers.length - startIndex;
      }
      for (let i = 0; i < this.pageSize * this.currentPageNo - startIndex; i++) {
        if (startIndex + i < this.availableUsers.length) {
          this.loadUserTag(startIndex + i);
        }
      }
      this.prevPageNo = this.currentPageNo;
    }
  }

  protected loadUserTag(index: number): void {
    UserService.getById(this.availableUsers[index].uid)
      .then((taggedUser) => {
        taggedUser.fullName = this.availableUsers[index].fullName;
        taggedUser.tagsChanged = false;
        this.systemUserWithTags.push(taggedUser);

        this.loadCounter--;
      })
      .catch(() => {
        this.loadCounter--;
      })
      .finally(() => {
        if (this.loadCounter === 0) {
          this.setLoading(false);
        }
      });
  }

  protected changedTags(user: UserTagged): void {
    user.tagsChanged = true;
    this.changedValue = true;
  }

  protected saveChanges(): void {
    if (this.availableUsers.length > 0) {
      this.saveChangesHelper(0);
    } else {
      this.changedValue = false;
    }
  }

  protected saveChangesHelper(index: number): void {
    if (index === 0) {
      this.setLoading(true);
    }
    const user = this.systemUserWithTags[index];
    if (user) {
      if (user.tagsChanged) {
        UserService.update(user.id, new UserTagChanges(user.tagIds))
          .then(() => {
            this.saveChangesHelper(index + 1);
          })
          .catch(() => {
            this.setLoading(false);
            this.showToast('Failed to save', 'Could not save tags.', 'danger');
          });
      } else {
        this.saveChangesHelper(index + 1);
      }
    } else {
      this.showToast('Successfully saved', 'Tags assigned to users successfully', 'success');
      this.setLoading(false);
      this.changedValue = false;
    }
  }

  protected saveTag(newTag: string, user: UserTagged): void {
    if (newTag.length < 256) {
      if (hasPermission(PossibleAction.CAN_SAVE_TAG)) {
        this.$bvModal
          .msgBoxConfirm('Do you really want to save this as a global tag?', {
            title: "Save new tag: '" + newTag + "'",
            okVariant: 'primary',
            okTitle: 'Save Tag',
            cancelTitle: 'Cancel',
            hideHeaderClose: true,
            centered: true,
          })
          .then((confirmationResponse) => {
            if (confirmationResponse) {
              this.tagCreationEnteredName = newTag;
              this.createTag(undefined, user);
            }
          });
      } else {
        this.showToast('Action denied', 'You do not have the required rights.', 'danger');
      }
    } else {
      this.showToast('Failed to create', 'Tag exceeds maximum length of 255.', 'danger');
    }
  }

  protected createTag(event?: Event, user?: UserTagged): void {
    if (hasPermission(PossibleAction.CAN_SAVE_TAG)) {
      if (event) {
        event.preventDefault();
      }
      if (this.tagCreationEnteredName.length > 0 && this.tagCreationEnteredName.length < 256) {
        if (user) {
          this.setLoading(true);
        } else {
          this.tagCreationInProgress = true;
        }
        TagService.save(new Tag(this.tagCreationEnteredName))
          .then((savedTag) => {
            this.setAvailableTags(this.availableTags.concat(Array.of(savedTag)));
            if (user && savedTag.id) {
              user.tagIds.push(savedTag.id);
              user.tagsChanged = true;
              this.changedValue = true;
            } else {
              this.$bvModal.hide('modal-create');
            }
            this.showToast(
              'Successfully created',
              "Tag '" + this.tagCreationEnteredName + "' was successfully created",
              'success'
            );
          })
          .catch((backendError) => {
            if (user) {
              if (backendError.response.status === 403) {
                this.showToast('Action denied', 'You do not have the required rights.', 'danger');
              } else {
                this.showToast(
                  'Failed to create',
                  "Tag '" + this.tagCreationEnteredName + "' already exists.",
                  'danger'
                );
              }
            } else {
              if (backendError.response.status === 403) {
                this.$bvModal.hide('modal-create');
                this.showToast('Action denied', 'You do not have the required rights.', 'danger');
              } else {
                this.tagCreationValidatorState = false;
                this.tagCreationErrorMessage = 'This tag does already exist.';
              }
            }
          })
          .finally(() => {
            if (user) {
              this.setLoading(false);
            } else {
              this.tagCreationInProgress = false;
            }
          });
      } else {
        this.tagCreationValidatorState = false;
        this.tagCreationErrorMessage = 'Required (max. Length 255)';
      }
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected selectTag(tag: Tag[]): void {
    this.selectedTag = tag.pop() ?? null;
  }

  protected deleteTag(): void {
    if (hasPermission(PossibleAction.CAN_DELETE_TAG)) {
      if (this.selectedTag) {
        this.$bvModal
          .msgBoxConfirm('Do you really want to delete this tag?', {
            title: "Delete Tag: '" + this.selectedTag.name + "'",
            okVariant: 'danger',
            okTitle: 'Delete',
            cancelTitle: 'Cancel',
            hideHeaderClose: true,
            centered: true,
          })
          .then((confirmationResponse) => {
            if (confirmationResponse && this.selectedTag && this.selectedTag.id) {
              this.setLoading(true);
              TagService.delete(this.selectedTag.id)
                .then(() => {
                  if (this.selectedTag) {
                    this.availableTags.splice(this.availableTags.indexOf(this.selectedTag), 1);
                    this.setAvailableTags(this.availableTags);
                    this.showToast(
                      'Successfully deleted',
                      "Tag '" + this.selectedTag.name + "' was successfully deleted",
                      'success'
                    );
                    this.selectedTag = null;
                  }
                })
                .catch((backendError) => {
                  if (backendError.response.status === 403) {
                    this.showToast('Action denied', 'You do not have the required rights.', 'danger');
                  } else {
                    if (this.selectedTag) {
                      this.showToast(
                        'Failed to delete',
                        "Tag '" + this.selectedTag.name + "' is in use for assignments or users.",
                        'danger'
                      );
                    }
                  }
                })
                .finally(() => this.setLoading(false));
            }
          });
      } else {
        this.showToast('Delete denied', 'Please select a tag to delete.', 'danger');
      }
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected tagCreationReset(): void {
    this.tagCreationEnteredName = '';
    this.tagCreationValidatorState = null;
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected getColumnStyle(columnName: string): string {
    switch (columnName) {
      case 'uid':
        return 'width: 150px;';
      case 'fullName':
        return 'width: 300px;';
      default:
        return '';
    }
  }

  protected confirmUnsavedChanges(): boolean {
    return window.confirm(this.confirmUnsavedChangesString);
  }

  get confirmUnsavedChangesString(): string {
    return 'Do you really want to leave? You have unsaved changes!';
  }

  protected handleMultiselectInput(user: UserTagged, value: Tag[]): void {
    user.tagIds = [];
    value.forEach((tag) => {
      if (tag.id) {
        user.tagIds.push(tag.id);
      }
    });
  }

  protected mapTagIdsToTags(tagIds: number[]): Tag[] {
    const tags: Tag[] = [];
    tagIds.forEach((tagId) => {
      const tag = this.availableTags.find((tag) => tag.id === tagId);
      if (tag) {
        tags.push(tag);
      }
    });
    return tags;
  }

  protected showUploadModal(): void {
    this.$bvModal.show('modal-import-users');
  }
}
