import { render, staticRenderFns } from "./SystemAdministration.vue?vue&type=template&id=2de9ae3e&scoped=true&"
import script from "./SystemAdministration.vue?vue&type=script&lang=ts&"
export * from "./SystemAdministration.vue?vue&type=script&lang=ts&"
import style0 from "./SystemAdministration.vue?vue&type=style&index=0&id=2de9ae3e&scoped=true&lang=css&"
import style1 from "./SystemAdministration.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2de9ae3e",
  null
  
)

export default component.exports